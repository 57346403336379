import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';

const NewsItemTemplate = ({ title, content, contentComponent, date }) => {
  const PageContent = contentComponent || Content;

  return (
    <section className="section">
      <h2 className="title">{title}</h2>
      <p className="subtitle is-size-7">{date}</p>
      <PageContent className="content" content={content} />
    </section>
  );
};

const NewsItem = ({ data }) => {
  const { html, frontmatter } = data.newsItem;

  return (
    <Layout>
      <NewsItemTemplate
        contentComponent={HTMLContent}
        title={frontmatter.title}
        content={html}
        date={frontmatter.date}
      />
    </Layout>
  );
};

NewsItem.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default NewsItem;

export const newsItemQuery = graphql`
  query NewsItem($id: String!) {
    newsItem(id: { eq: $id }) {
      html
      frontmatter {
        rowId
        title
        date(formatString: "l", locale: "fi")
      }
    }
  }
`;
